.summary * {
  color: var(--primary-2);
}
.summary h1 {
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
  font-size: 2.25rem;
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
}
