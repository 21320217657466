.steps {
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  padding-left: 1.25rem;
  border-left: 1px #d9d9d9 solid;
  font-size: var(--f-size-14px);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  color: var(--ui-5);
  overflow: hidden;
  white-space: nowrap;
  width: 98%;
}
