.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 3.125rem 0 1.5625rem;
  background-color: var(--ui-1);
  box-shadow: 0rem 0.25rem 0.25rem -0.125rem rgba(37, 42, 49, 0.12);
  z-index: var(--zindex-medium);
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.container div:global(.padded-view) {
  min-width: 100%;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr auto;
  height: 3.875rem;
}
.container div:global(.padded-view) > a:first-of-type {
  display: flex;
}
.logo {
  width: 7.3125rem;
  margin-right: 1.25rem;
  cursor: pointer;
}
.logo img {
  width: 100%;
}

.container div:global(.padded-view) > nav {
  min-width: 100%;
  white-space: nowrap;
}
