.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container.guestMenu {
  flex-direction: column;
  justify-content: flex-start;
  width: 360px;
  row-gap: 15px;
  height: calc(100vh - 60px);
  padding-bottom: 25px;
  padding-top: 25px;
}

.container.guestMenu.mobile {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  row-gap: 0;
  padding: 0.625rem 0 0;
  min-height: 100%;
  /* overflow-x: hidden;
  overflow-y: scroll; */
}

.banner {
  margin: 1rem 0;
  width: 17.5rem;
  height: 8.75rem;
}

.banner img {
  height: 100%;
}

.mobile .banner {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 14.5rem;
  max-height: 8rem;
}

.mobile .banner img {
  height: 100%;
  width: 100%;
}

.mobile .middle {
  margin-bottom: 2.5rem;
  row-gap: 0.75rem;
}

.middle {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 18px;
  margin-bottom: 15px;
}

p.container {
  flex-direction: column;
  font-family: var(--font-regular);
  font-size: var(--fmob-size-16px);
  font-weight: var(--regular-w);
  line-height: 1.125rem;
  color: var(--primary-2);
}

p.container span {
  text-align: center;
}

p.container span b {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
}

.mobile p.container {
  margin-bottom: 0.5rem;
}

.mobile p.container > * {
  font-family: var(--font-regular);
  font-size: var(--fmob-size-16px);
  line-height: 1.125rem;
  font-weight: var(--regular-w);
}

.textLink {
  color: var(--primary-1);
  font-family: var(--font-bold);
  font-size: var(--f-size-16px);
  font-weight: var(--bold-w);
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-skip-ink: none;
  background: none;
  padding: 0;
  border: none;
}

.mobile .textLink {
  font-family: var(--font-bold);
  font-size: var(--fmob-size-18px);
  font-weight: var(--bold-w);
}

.infolinks {
  width: 100%;
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--fmob-size-16px);
  font-weight: var(--medium-w);
}

.infolinks span.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  min-height: 55px;
  border: solid #e8edf1;
  border-width: 0 0 1px;
}

.infolinks span.container span {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
}

.mobile .infolinks {
  font-family: "Proxima Nova-Medium";
  font-size: var(--fmob-size-16px);
  font-weight: 500;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.container.imageGroup {
  justify-content: center;
  column-gap: 1rem;
  width: 22.5rem;
  flex-basis: 10%;
}

.mobile .container.imageGroup {
  padding-top: 1.44rem;
  padding-bottom: 1.88rem;
  justify-content: center;
  column-gap: 0.62rem;
  width: 100%;
}

.mobile .container.imageGroup img {
  width: 50%;
}

.mobile .container.imageGroup a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--ui-2);
  width: 2.5rem;
  height: 2.5rem;
}

.close {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  right: 0.625rem;
  top: 0.625rem;
  border: none;
  background: none;
  border-radius: var(--corner-sm);
  padding: 0.7rem;
}
.close:hover {
  background-color: var(--ui-3);
  transition: all 0.15s;
}
.close svg {
  width: 70%;
}