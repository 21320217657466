@import "./styleguide.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

/***FOR DEBUGGING***/
/* *{
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
/******************/

body,
html {
  margin: 0 auto;
  padding: 0;
  font-family: sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100vh; */
  width: 100%;
  font-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--UI-background);
  color: var(--primary-2);
  min-width: 360px;
}

html .mobile,
body .mobile {
  background-color: #fff;
}

html[class*="noscroll"] {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
}

html[class*="noscroll"]::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: var(--primary-2);
  transition: all 0.15s;
}

a:active {
  color: var(--primary-link-color);
}
a:hover {
  color: var(--primary-link-color);
  transition: all 0.15s;
}

button {
  cursor: pointer;
}

textarea:focus,
input:focus,
/* button:focus, */
/* a:focus, */
select:focus {
  outline: none;
}

/* input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: 1px var(--ui-4) solid !important;
  transition: outline 0.15s;
} */

/** Breakpoints for padding change **/
@media only screen and (min-width: 1440px) {
  body {
    align-items: center;
  }
  .padded-view {
    max-width: var(--main-page-content-max-width);
    /* min-width: var(--main-page-content-max-width); */
  }
}

/** Root React content **/
div#root {
  width: inherit;
  overflow-x: clip;
  position: relative;
  /* padding-left: calc((100vw - 17px - 100%) / -2); */
}

.mob div#root {
  padding-left: 0;
}
div#root-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/** HOME PAGE **/
main#tickets-home-page {
  display: flex;
  flex-direction: column;
}

main#tickets-home-page.mobile {
  padding: 2.5rem 1rem 0;
  row-gap: 3.75rem;
}

/** RESULTS PAGE **/
main#search-results-page {
  display: flex;
  flex-direction: column;
}

section#search-form-section {
  margin: auto;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background-image: none;
}

.desktop section.results-header {
  position: sticky;
}

main#search-results-page section.results-content {
  background-color: var(--UI-background);
  min-height: 100vh;
}

.desktop section.results-header .padded-view,
.desktop section.results-content .padded-view {
  padding: 0 var(--main-page-side-pad);
  margin: auto;
}

.desktop section.results-content .padded-view {
  display: grid;
  grid:
    "left-panel sorting right-panel" auto
    "left-panel baggage right-panel" auto
    "left-panel results right-panel" 1fr
    / 1fr 2.92fr 1fr;
  gap: 0 1.25rem;
}

.left-panel,
.right-panel,
.results {
  display: flex;
  flex-direction: column;
}

.left-panel {
  position: sticky;
  grid-area: left-panel;
  justify-content: flex-start;
  padding-bottom: 1rem;
}

.sorting {
  grid-area: sorting;
  background-color: var(--ui-1);
  display: flex;
  flex-direction: row;
}

.right-panel {
  grid-area: right-panel;
  row-gap: 1.25rem;
  max-height: 22.4375rem;
}

.baggage {
  grid-area: baggage;
  background-color: var(--ui-1);
}

.results {
  grid-area: results;
  row-gap: 1rem;
  padding-bottom: 1rem;
}

.desktop section.results-content {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.mobile section.results-content {
  padding: 1.125rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  min-height: 100vh;
}

/** BOOKING PAGE **/
main#booking-cancelled-page,
main#order-complete-page,
main#order-completion-error-page,
main#booking-page {
  background-color: var(--UI-background);
  min-height: 100vh;
  padding: 0;
}
main#booking-page.desktop {
  display: grid;
  grid-template-columns:
    minmax(var(--main-page-side-pad), 1fr)
    minmax(auto, var(--main-page-inner-max-width))
    minmax(var(--main-page-side-pad), 1fr);
  grid-template-rows: auto 1fr;
  align-items: center;
}
main#booking-page.mobile {
  display: flex;
  flex-direction: column;
}
main#booking-page.desktop .booking-logo {
  display: flex;
  align-items: center;
}
main#booking-page.desktop .booking-logo img {
  width: 5.4375rem;
  margin-right: 1.5625rem;
}
main#booking-page.desktop .booking-summary {
  grid-column: 2;
  margin-top: 6.125rem;
}
main#booking-page.desktop .booking-content {
  grid-area: 2 / 2;
  align-self: normal;
}
main#booking-page.mobile .booking-content {
  position: relative;
}

section#unknown-error-occured.body,
section#page-not-found.body {
  text-align: center;
  padding: 5rem var(--main-page-side-pad) 7rem;
  height: 100vh;
}

section#unknown-error-occured.body > *,
section#page-not-found.body > * {
  margin: 0 auto;
}

section#unknown-error-occured.body h1,
section#page-not-found.body h1 {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 2.75rem;
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  color: var(--ui-4);
}
section#unknown-error-occured.body p,
section#page-not-found.body p {
  font-size: 1.2rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 0.5rem;
  margin: 1rem 0 1.5rem;
}

section#unknown-error-occured.body.mob {
  padding: 3rem 1rem 4.5rem;
  text-align: center;
}
section#page-not-found.body.mob {
  padding: 3rem 1rem 4.5rem;
  text-align: center;
}

section#page-not-found.body.mob h1,
section#unknown-error-occured.body.mob h1 {
  font-size: 2.25rem;
}

section#page-not-found.body.mob p,
section#unknown-error-occured.body.mob p {
  font-size: 1.1rem;
  row-gap: 1rem;
}
main#booking-cancelled-page .order-container,
main#order-completion-error-page .order-container,
main#order-complete-page .order-container {
  position: relative;
  top: 2rem;
  padding: 2.5rem;
  margin: auto;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  background-color: var(--ui-1);
  border-radius: var(--corner-xl);
  box-shadow: var(--booking-card-shadow);
  text-align: center;
}

main#booking-cancelled-page.mobile,
main#order-completion-error-page.mobile,
main#order-complete-page.mobile {
  padding: 1rem;
}
main#booking-cancelled-page.mobile .order-container,
main#order-completion-error-page.mobile .order-container,
main#order-complete-page.mobile .order-container {
  padding: 2rem 1.5rem;
  line-break: loose;
}
main#booking-cancelled-page .order-container > h1,
main#order-completion-error-page .order-container > h1,
main#order-complete-page .order-container > h1 {
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  font-size: var(--f-size-20px);
  color: #00a991;
  margin-bottom: 1rem;
}
main#booking-cancelled-page .order-container > h1 {
  color: var(--primary-2);
}
main#order-completion-error-page .order-container > h1 {
  color: var(--error);
}
main#booking-cancelled-page .order-container > h2,
main#order-completion-error-page .order-container > h2,
main#order-complete-page .order-container > h2 {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-18px);
  margin-bottom: 0.5rem;
}
main#booking-cancelled-page.mobile .order-container > h2,
main#order-completion-error-page.mobile .order-container > h2,
main#order-complete-page.mobile .order-container > h2 {
  font-size: var(--f-size-16px);
}
main#booking-cancelled-page .order-container > h2 span,
main#order-completion-error-page .order-container > h2 span,
main#order-complete-page .order-container > h2 span {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  line-break: normal;
}
main#order-complete-page .order-container pre {
  padding: 2rem 0;
}
main#order-complete-page .order-container > h2 + p {
  margin-bottom: 1.75rem;
}
main#booking-cancelled-page .order-container > h2 + p,
main#order-completion-error-page .order-container > h2 + p {
  margin-top: 1.25rem;
}

.info-body {
  background-color: #fff;
}
main#info-pages-container {
  width: 100%;
  background-color: var(--ui-1);
}
main#info-pages-container.desktop .padded-view {
  padding: 3.75rem var(--main-page-side-pad) 6.5rem;
  margin: auto;
}
main#info-pages-container.mobile {
  width: 100%;
  padding: 2rem 1rem 3.5rem;
}
#info-pages-bottom-graphic {
  position: relative;
  height: 20.93rem;
  margin: 0 -1rem;
  overflow: hidden;
}
#info-pages-bottom-graphic .graphic-container {
  position: absolute;
  width: 100%;
}
#info-pages-bottom-graphic.mobile .graphic-container {
  position: absolute;
  bottom: -5%;
  width: 100%;
}
#info-pages-bottom-graphic.mobile .graphic-container svg {
  position: absolute;
  bottom: 0;
  height: 20rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #info-pages-bottom-graphic .graphic-container svg {
    bottom: 0;
    width: 100%;
    transform: scale(1.5) translateY(2rem);
  }
}

@media screen and (min-width: 2200px) {
  #info-pages-bottom-graphic {
    height: 25rem;
  }

  #info-pages-bottom-graphic .graphic-container svg {
    bottom: 0;
    width: 100%;
  }
}

main#booking-page section.booking-content-loading {
  column-gap: 1.25rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
}

main#booking-page section.booking-content-loading .main-panel {
  display: flex;
  flex-direction: column;
  row-gap: 1.875rem;
  width: 100%;
  padding-top: 1.5rem;
}
main#booking-page.mobile section.booking-content-loading {
  display: unset;
}
main#booking-page section.booking-content-loading .main-panel > .main-panel-item {
  height: 14.93rem;
  background-color: var(--ui-1);
  border: var(--booking-border);
  border-radius: var(--corner-xl);
  color: var(--primary-2);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.875rem;
}
main#booking-page.mobile section.booking-content-loading .main-panel > .main-panel-item {
  border-radius: 0;
}
main#booking-page section.booking-content-loading .side-panel {
  margin-top: 1.5rem;
  grid-column: 2;
  grid-row: 1 / span 2;
  max-width: 21.56rem;
  min-width: 21.56rem;
  overflow-y: clip;
  position: relative;
  background-color: var(--ui-1);
  border: var(--booking-border);
  border-radius: var(--corner-xl);
  color: var(--primary-2);
  height: 27rem;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

@keyframes loadingGradient {
  0% {
    background-position-x: 20%;
  }
  100% {
    background-position-x: -150%;
  }
}

main#booking-page section.booking-content-loading .main-panel > .main-panel-item > div,
main#booking-page section.booking-content-loading .side-panel > div {
  border-radius: 0.25rem;

  background: var(--ui-1);
  background: linear-gradient(
    250deg,
    rgba(233, 238, 242, 0.25) 0%,
    rgba(235, 238, 240, 0.75) 50%,
    rgba(233, 238, 242, 0.25) 100%
  );
  background-size: 250% 100%;
  animation: loadingGradient 1s linear infinite;
  -webkit-animation: loadingGradient 1s linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

section#unknown-error-occured svg {
  height: 100%;
  width: auto;
}