.container {
  position: sticky;
  z-index: var(--zindex-high);
  top: 0;
  left: 0;
  background-color: var(--ui-1);
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 0.6875rem 1rem;
  box-shadow: 0rem 0.25rem 0.25rem -0.125rem rgba(37, 42, 49, 0.12);
}
.container.minimal {
  background: transparent;
  box-shadow: none;
}
.container .prev button {
  padding: 0.5rem;
  margin: -0.5rem;
}
.container .back button {
  background: none;
  padding: 0;
  border: none;
}
.container .details {
  justify-self: end;
}
.container .details button {
  padding: 0.5rem;
  border-radius: var(--corner-sm);
  font-size: var(--fmob-size-14px);
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  line-height: 1.125rem;
  min-width: 3.875rem;
}
.container .summary {
  text-align: center;
  line-height: 1.125rem;
}
.cities {
  font-size: var(--fmob-size-16px);
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
}
.dates {
  font-size: var(--fmob-size-14px);
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
}
.dates span {
  margin-left: 0.25rem;
}
.paxIcon {
  width: 0.625rem;
}
.container.minimal {
  display: block;
  position: relative;
  background-color: transparent;
  padding: 1.1875rem 1rem;
}
.backdrop {
  width: 100%;
  overflow-x: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: auto;
}
.backdrop svg {
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: scaleX(1.2);
  max-height: 33rem;
}
