.container {
  display: flex;
  flex-direction: column;
}

ul.cityList {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--medium-w);
  margin-top: 0.7rem;
  row-gap: 0.25rem;
}

ul.cityList.filtered {
  row-gap: 0.25rem;
  margin-top: 0;
}

main:global(.desktop) ul.cityList {
  row-gap: 0rem;
  font-family: var(--font-medium);
  font-size: var(--f-size-16px);
  font-weight: var(--medium-w);
  margin-top: 0;
}

main:global(.desktop) ul.cityList.filtered {
  margin-top: -0.625rem;
}

li.cityItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

main:global(.desktop) li.cityItem {
  min-height: 3.125rem;
  justify-content: center;
}

li.cityItem div.row {
  column-gap: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: fit-content;
  overflow: hidden;
  width: 100%;
}

main:global(.desktop) ul.cityList.filtered div.row .airportList {
  row-gap: 0;
}

main:global(.desktop) li.cityItem div.row:first-of-type {
  height: 3.125rem;
}
li.cityItem div.row:first-of-type {
  height: 3.125rem;
}

li.cityItem img,
li.cityItem p,
li.cityItem div.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

li.cityItem .row img,
li.cityItem .col::after {
  min-width: 1.125rem;
  max-width: 1.125rem;
}

li.cityItem .row p {
  display: inline;
}
li.cityItem .row p > span {
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  background-color: var(--ui-2);
  border-radius: 0.3125rem;
  padding: 0.25rem;
  font-size: var(--f-size-14px);
  margin-left: 0.25rem;
}
li.cityItem .row:hover p > span {
  background-color: var(--ui-4);
}

li.cityItem div.col::after {
  content: "";
}

main:global(.desktop) li.cityItem div.col {
  width: 0.5rem;
}

ul.airportList {
  flex-basis: 100%;
  overflow: hidden;
}

main:global(.desktop) ul.airportList {
  row-gap: 0.5rem;
  margin: 0 0 0 2.5rem;
}

li.airportItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  opacity: 0;
  transform: translateY(-110%);
  transition: all 50ms ease-out;
  height: 3.125rem;
}

main:global(.desktop) li.cityItem,
main:global(.desktop) li.airportItem {
  height: 3.125rem;
  align-items: center;
  border-radius: var(--corner-sm);
  padding: 0 1rem;
  column-gap: 1.25rem;
}

main:global(.desktop) li.cityItem:hover,
main:global(.desktop) li.airportItem:hover {
  background-color: var(--ui-2);
}

main:global(.desktop) li.cityItem,
main:global(.desktop) li.airportItem {
  cursor: pointer;
}

main:global(.desktop) li.airportItem > div {
  row-gap: 0;
}

li.airportItem.anim {
  opacity: 100%;
  transform: none;
  transition: all 50ms ease-out;
}

li.airportItem > div {
  row-gap: 0.12rem;
}

li.cityItem.cityOnly p + p,
li.airportItem p + p {
  color: var(--ui-5);
}

main:global(.desktop) li.cityItem.cityOnly p + p,
main:global(.desktop) li.airportItem p + p {
  line-height: 1.375rem;
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
}

.airportOnly li {
  column-gap: 1.25rem;
}
