.footer-mobile footer .footer-view {
  margin-top: 1rem !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 0 !important;
  padding: 1rem !important;
}
.footer-mobile footer .footer-view .footer-social div,
.footer-mobile footer .footer-view .footer-social div a {
  width: 1.875rem;
  height: 1.875rem;
}
.footer-mobile footer .footer-view .footer-social,
.footer-mobile footer .footer-view .footer-logo img {
  max-height: 1.5rem !important;
}
.footer-mobile footer .footer-view .footer-social {
  margin-bottom: 1.5rem !important;
}
.footer-mobile footer .footer-view .footer-column {
  flex-basis: 100%;
}
.footer-mobile footer .footer-heading {
  margin-top: 0 !important;
  padding: 1rem 0 !important;
  font-size: var(--f-size-14px);
  font-weight: var(--bold-w);
  letter-spacing: 0.01rem;
}
.footer-mobile footer .footer-heading.collapsed {
  border-bottom: 1px solid var(--ui-3);
}
.footer-mobile footer .footer-heading::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  background-image: url("/src/assets/img/collapseArrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: 1rem;
  margin-top: 3px;
}
.footer-mobile footer .footer-heading.collapsed::after {
  background-image: url("/src/assets/img/expandArrow.svg");
}
.footer-mobile footer .footer-links {
  font-size: var(--f-size-16px);
  margin: 0.75rem 0 !important;
  row-gap: 1rem !important;
}
.footer-mobile footer .footer-heading.collapsed + .footer-links {
  display: none;
}
.footer-mobile footer .footer-links a:hover {
  color: var(--primary-link-color) !important;
}
